import React from 'react';
import { Message, Header, Menu, Segment, Image, Divider} from 'semantic-ui-react'

function App() {
  return (
    <div className="app">
      <Message info color="orange" 
          icon='wrench'
          header='Site Under Construction'
          content='Check back soon for updates!'>
        </Message>
    
      <div className="card-holder">
        <Segment className="card-sub-holder">
          <Image src='./img/46NLogoNew.jpg' />
          <Header as="h6">Est. 2011</Header>
          <Divider></Divider>
          <Header as="h4" textAlign="center" >
            A nonprofit organizing to  highlight the culture and  activities 
            across the 46N parallel by building  a unique 
            apolitical alliance of 100’s of  cities and towns 
            and identifying their unique qualities, initially 
            in North America
            </Header>
        </Segment>
      </div>

      <Menu id="footer" secondary fluid>
        <Menu.Item>
          <a href='/'>home</a>
        </Menu.Item>
      </Menu>

    </div>
  );
}

export default App;
